<script>
import { 
  ArrowUpIcon, 
  VideoIcon,  
  MonitorIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon, 
  HeartIcon,
  EyeIcon,
  BoldIcon,
  FeatherIcon,
  CodeIcon,
  UserCheckIcon,
  GitMergeIcon,
  SettingsIcon,
  PhoneIcon,
  MapPinIcon,
  SmartphoneIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import countTo from "vue-count-to";

import Navbar from "@/components/navbar";
import Features from "@/components/features";
import Footer from "@/components/footer";

/**
 * Index-services component
 */
export default {
  data() {
    return {
        featuresData: [
        {
          icon: "uil uil-flip-h",
          title: "Boost growth",
          description:
            "Get connected to partners worldwide, build awareness and drive more bookings.",
        },
        {
          icon: "uil uil-minus-path",
          title: "Save cost",
          description:
            "Automate as much as possible, save thousands of hours with simple & powerful automation.",
        },
        {
          icon: "uil uil-layers-alt",
          title: "Improve management",
          description:
            "Say goodbye to endless paperwork and excel, make better business decisions with business intelligence reporting tools.",
        },
      ],
      featuresData2: [
        {
          icon: "uil uil-edit-alt",
          title: "Official account operation",
          description:
            "Crafting high-quality content that aligns with local culture and attracts the target audience. Dedicated to transforming the official account into a representative of the brand's Chinese website.",
        },
        {
          icon: "uil uil-vector-square",
          title: "Influencer collaboration",
          description:
            "Combining KOL marketing and KOC marketing, with a focus on promoting user-generated content (UGC), our aim is to achieve broader and deeper brand exposure and user interaction.",
        },
        {
          icon: "uil uil-file-search-alt",
          title: "Advertising placement",
          description:
            "Developing precise advertising strategies aligned with specific needs, we cover all scenarios to enhance brand visibility, capturing the attention of the target audience and increasing exposure.",
        },
      ],

      featuresData3: [
        {
          icon: "uil uil-airplay",
          title: "Traffic conversion sales",
          description:
            "Focusing not only on brand exposure but also on the actual conversion process, your brand can achieve substantial business growth in the Chinese market through the Treep Booking Engine.",
        },
      ],
    };
  },
  components: {
    Navbar,
    Carousel,
    Slide,
    MonitorIcon,
    ArrowUpIcon,
    VideoIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
    countTo,
    Features,
    Footer,
    HeartIcon,
    EyeIcon,
    BoldIcon,
    FeatherIcon,
    CodeIcon,
    UserCheckIcon,
    GitMergeIcon,
    SettingsIcon,
    PhoneIcon,
    MapPinIcon,
    SmartphoneIcon,
  },
};
</script>

<template>
  <div>
    <Navbar />
    <!-- Hero Start -->
    <section class="bg-half-170 d-table w-100" id="home">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-7">
            <div class="title-heading mt-4">
              <h1 class="heading mb-3">
                Asia-Pacific leading <br />
                travel business <br />
                system and marketing <br />
                solution provider
              </h1>
              <p class="para-desc text-muted">
                Travel business system designed to help operators and resellers globally save time, get connected and ultimately grow your business.
              </p>
              <div class="watch-video mt-4 pt-2">
                <a href="https://treep.cc/demo/?channelType=1"
                  target="_blank"
                  class="btn btn-primary"
                  >Book a meeting</a>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <img src="images/template/Startup_SVG.svg" alt="" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Feature Start -->
    <section class="section pt-0">
      <div class="container">
         <Features :featuresData="featuresData" />
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature End -->

    <!-- How It Work Start -->
    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">How it works ?</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Start working with
                <span class="text-primary font-weight-bold">TREEP</span>, system plus marketing magnify your business.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6 mt-4 pt-2">
            <img src="images/template/SEO_SVG.svg" alt="" />
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 mt-4 pt-2">
            <div class="section-title ml-lg-5">
              <h4 class="title mb-4">Manage your business in one place</h4>
              <p class="text-muted">
                Whether you're selling online, manually entering bookings or getting sales from OTAs, TREEP is the single place where you can track all of your bookings across every channel.
              </p>
              <router-link to="/pms" class="btn btn-primary mt-2 mr-2">
                PMS: Property Management System
              </router-link>

            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div
            class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
          >
            <div class="section-title">
              <h4 class="title mb-4">
                Get connected to the world's largest distribution network
              </h4>
              <p class="text-muted">
                Looking for a faster and easier way to grow your sales network? Connect with OTAs via 1 API connection –two-ways sync that updated in real time – automating processes and unlocking new revenue streams for all parties involved.
              </p>
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >All you need to manage partnerships in one place
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Best in class, award-winning connectivity
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Innovating for the future of channel management 
                </li>
              </ul>
              <router-link to="/channelmanager" class="btn btn-primary mt-2 mr-2">
                Channel Manager
              </router-link>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 order-1 order-md-2">
            <img src="images/template/deal-hend.svg" class="img-fluid" alt="" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->

    <!--end section-->
    <!-- How It Work End -->

    <!-- Key Feature Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Find the perfect partner for your business</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                We connect you to more global and local agents, resellers and distribution channels than anyone else in the industry.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
          <div class=" mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/partners/klook.jpg" height="60">
                  </div>

              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/partners/trip.jpg" height="60">
                  </div>
              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/partners/meituan.jpg" height="60">
                  </div>
              </div>
          </div>
          <div class=" mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/partners/fliggy.jpg" height="60">
                  </div>

              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/partners/gyg.jpg" height="60">
                  </div>
              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/partners/booking.jpg" height="60">
                  </div>
              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/partners/expedia.jpg" height="60">
                  </div>
              </div>
          </div>
          <div class="mt-4 ml-4 pt-2">
              <div class="card blog rounded border-0 shadow text-center">
                  <div class="card-body m-3">
                    <img src="images/partners/traveloka.jpg" height="60">
                  </div>
              </div>
          </div>

          <div class="col-12 mt-4 pt-2 text-center">
            <router-link to="/channelmanager" class="btn btn-primary mt-2 mr-2">
                Channel Manager
              </router-link>
          </div>
        </div>
        <!--end row-->
      </div>
      <!--end container-->


      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div
            class="col-lg-6 col-md-6 col-12 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
          >
            <img src="images/template/app.svg" alt="" />
          </div>
          <!--end col--->

          <div class="col-lg-6 col-md-6 col-12 order-1 order-md-2">
            <div class="section-title">
              <h4 class="title mb-4">
                Boost your online direct bookings, <br />get official website with one click
              </h4>
              <p class="text-muted">
                Take your business online in just a few steps. Our Booking Engine is the easiest way to get your products online and make them bookable. Whenever you add or update products in TREEP, your website is instantly updated. 
              </p>
              <router-link to="/bookingengine" class="btn btn-primary mt-2 mr-2">
                Booking Engine
              </router-link>
            </div>
          </div>
          <!--end col--->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div
            class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
          >
            <div class="section-title mr-lg-5">
              <h4 class="title mb-4">Empower your partners resell activities with ease</h4>
              <p class="text-muted">
                Travel agencies offer vital partnerships for any tour and activity business, and TREEP lets you take them further. TREEP Agent Portal is a best choice  for travel agenies booking on behalf of customers.
              </p>
              <router-link to="/agentportal" class="btn btn-primary mt-2 mr-2">
                Agent Portal
              </router-link>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-5 col-md-6 order-1 order-md-2">
            <img src="images/template/app_development_SVG.svg" alt="" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container mt-100 mt-60">
            <div class="rounded bg-primary p-lg-5 p-4">
                <div class="row align-items-end">
                    <div class="col-md-8">
                        <div class="section-title text-md-left text-center">
                            <h4 class="title mb-3 text-white title-dark">Got qustions? Get in touch !</h4>
                            <p class="text-white-50 mb-0">There is no traditional industry but traditional mindset in the internet era. Even small investment can help you reap the benefit.</p>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-4 mt-4 mt-sm-0">
                        <div class="text-md-right text-center">
                            <a href="https://treep.cc/demo/?channelType=1" target="_blank" class="btn btn-light">Book a meeting</a>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Key Feature End -->

    <!-- Work Process Start -->
    <section class="section bg-light">
      <!--(.work-process) css write in (_feature.scss)-->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Get on board TREEP system in three simple steps</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                We have extensive training experience for tourism industry clients and can help you get up to speed with the <span class="text-primary font-weight-bold">TREEP</span> system quickly. Your staff will love an all-in-one system that's easy to train and built for maximum flexibility.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-md-4 mt-4 pt-2">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body">
                <h4 class="title">Business analysis</h4>
                <p class="text-muted para">
                  Before implementing TREEP, it is important to conduct a thorough analysis of the business requirements. This includes identifying the business processes that need to be automated, understanding the data requirements, and identifying any potential challenges or risks.
                </p>
                <ul
                  class="list-unstyled d-flex justify-content-between mb-0 mt-2"
                >
                  <li class="step h1 mb-0 font-weight-bold">Step 01.</li>
                  <li class="step-icon">
                    <i class="mdi mdi-chevron-double-right mdi-36px"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-4 pt-2">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body">
                <h4 class="title">System blueprint design</h4>
                <p class="text-muted para">
                  Once the business requirements have been identified, the next step is to design a system blueprint. This involves creating a detailed plan for how the system will be implemented, including the hardware and software requirements, the system architecture, and the data flow.
                </p>
                <ul
                  class="list-unstyled d-flex justify-content-between mb-0 mt-2"
                >
                  <li class="step h1 mb-0 font-weight-bold">Step 02.</li>
                  <li class="step-icon">
                    <i class="mdi mdi-chevron-double-right mdi-36px"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-4 pt-2">
            <div class="card work-process border-0 rounded shadow">
              <div class="card-body">
                <h4 class="title">Training and deployment</h4>
                <p class="text-muted para">
                  After the system blueprint has been designed, the final step is to train the users and deploy the system. This includes developing training materials, conducting training sessions, and providing ongoing support to ensure that the system is being used effectively.
                </p>
                <ul
                  class="list-unstyled d-flex justify-content-between mb-0 mt-2"
                >
                  <li class="step h1 mb-0 font-weight-bold">Step 03.</li>
                  <li class="step-icon">
                    <i class="mdi mdi-check-all mdi-36px"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">
              <h4 class="title mb-4">
                Multilingual! Supporting English, Chinese, and Thai
              </h4>
              <p class="text-muted mb-0 mx-auto para-desc">
                This feature allows users to interact with the system in their preferred language, making it more accessible and user-friendly. Whether you are a native speaker of English, Chinese, or Thai, you can use the <span class="text-primary font-weight-bold">TREEP</span> system with ease. 
              </p>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="row" id="counter">
              <div class="col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="content text-center">
                  <h1 class="mb-0">
                    <countTo
                      :startVal="1"
                      :duration="4000"
                      :endVal="500"
                    ></countTo
                    >+
                  </h1>
                  <ul class="list-unstyled mb-0 h5">
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-heart text-danger"></i>
                    </li>
                  </ul>
                  <h6>Thai Version Users</h6>
                </div>
              </div>

              <div class="col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="content text-center">
                  <h1 class="mb-0">
                    <countTo
                      :startVal="1"
                      :duration="4000"
                      :endVal="1000"
                    ></countTo
                    >+
                  </h1>
                  <ul class="list-unstyled mb-0 h5">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6>English Version Users</h6>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6">
            <img src="images/template/1.png" class="img-fluid" alt="" />
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-lg-5">
              <h4 class="title mb-4">
                Data security is vital ! <br />Secure communication and all data backed daily
              </h4>
              <p class="text-muted">
                Powered by the most advanced third-party service providers. Data calculation and hosting service by Alibaba Cloud, the encryption method by Verisign.
              </p>
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Features like HIPAA, SOAP, Password security and SSL will make sure your data is safe
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >We also offer SSO and are ISO27001 compliant
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uil uil-check-circle align-middle"></i></span
                  >Our security officer to make sure your business remains safe with us
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Work Process End -->

    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Stop wasting time and get real results on social media</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Producing high-quality PGC content that aligns with local culture and captivates the target market, enhancing brand image and trust. The aim is to reach a broader audience, providing visual and measurable results to boost brand sales conversion rates and direct customer business.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="mt-5">
          <Features :featuresData="featuresData2" />
        </div>
        <div class="mt-5">
          <Features :featuresData="featuresData3" />
        </div>
          <div class="row justify-content-center">  
            <router-link to="/marketing" class="btn btn-primary mt-2 mr-2">
                  Marketing
                </router-link>
        </div>
      </div>
      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div
            class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
          >
          <img src="images/template/contact.svg" alt="" class="img-fluid">
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 order-1 order-md-2">
            <div class="title-heading ml-lg-4">
              <h4 class="mb-4">Contact Details</h4>
              <p class="text-muted">
                Know more about  <span class="text-primary font-weight-bold">TREEP</span>, don't hesitate to contact us.
              </p>
              <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <mail-icon class="fea icon-m-md text-dark mr-3"></mail-icon>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">Email</h5>
                  <a href="mailto:jerry.li@treep.cc" class="text-primary"
                    >jerry.li@treep.cc</a
                  >
                </div>
              </div>

              <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <phone-icon class="fea icon-m-md text-dark mr-3"></phone-icon>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">Phone</h5>
                  <a href="tel:+8615999956920" class="text-primary"
                    >+86 15999956920</a
                  ><br>
                  <a href="tel:+85254266686" class="text-primary"
                    >+852 54266686</a><br>
                    <a href="tel:+660935530595" class="text-primary"
                    >+66 0935530595</a>
                </div>
              </div>

              <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <map-pin-icon
                    class="fea icon-m-md text-dark mr-3"
                  ></map-pin-icon>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">Wechat</h5>
                  liyujerry
                </div>
              </div>
              <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <smartphone-icon class="fea icon-m-md text-dark mr-3"></smartphone-icon>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">WhatsApp</h5>
                  <a href="https://wa.me/85254266686" class="text-primary"
                    >+852 54266686</a
                  >
                </div>
              </div>
              <div class="media contact-detail align-items-center mt-3">
                <div class="icon">
                  <smartphone-icon class="fea icon-m-md text-dark mr-3"></smartphone-icon>
                </div>
                <div class="media-body content">
                  <h5 class="title font-weight-bold mb-0">LINE</h5>
                  <a href="https://line.me/ti/p/fC4odSDT8U" class="text-primary">liyujerry</a>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
    </section>

    <Footer />
    <!-- Footer End -->   
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>

</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
